<template>
    <div class="root-wrap">
        <div class="body-wrap">
            <div class="fx-fill">
                <div class="block">
                    <div class="title">充值号码</div>
                    <div class="input">
                        <input class="input" v-model="chargeAccount" :placeholder="product.placeholder || '请输入'" />
                    </div>
                    <div class="input" v-if="product.placeholderAppend" style="margin-top: 10px;">
                        <input class="input" v-model="chargeAccountAppend" :placeholder="product.placeholderAppend || '请输入'" />
                    </div>
                </div>
                <div class="block">
                    <div class="title">充值规格</div>
                    <div class="sku" style="flex-wrap: wrap">
                        <div v-for="item, index in tpList" :key="index" class="sku-item" :class="skuIndex == index ? 'active' : ''" @click="skuIndex = index">{{item.name}}</div>
                    </div>
                    <div class="sku sku-second-wrap ">
                        <div v-for="item, index in skuList" :key="index" class="sku-second fx-column" :class="skuSecondIndex == index ? 'active' : ''" @click="skuSecondIndex = index">
                            <div v-if="skuSecondIndex == index" class="sku-second--current">当前:特权会员</div>
                            <div class="sku-second--name">{{item.name}}</div>
                            <!-- <div class="sku-second--origin">官方价￥<span>{{item.officialPrice | formatMoney}}</span></div> -->
                            <div class="sku-second--price"><span style="font-size: 10px;">￥</span><span>{{item.price | formatMoney}}</span></div>
                            <!-- <div class="sku-second--common">普通价</div>
                            <div class="sku-second--common-price">￥183.00</div> -->
                        </div>
                    </div>
                </div>
                <div class="block" style="padding-bottom: 20px;">
                    <div class="title">使用说明</div>
                    <div v-html="product.description">
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap">
            <van-goods-action style="padding-left: 15px;">
                <van-goods-action-icon icon="service-o" text="联系客服" @click="call"></van-goods-action-icon>
                <van-goods-action-icon icon="todo-list-o" text="我的订单" @click="goPage('store-order')"></van-goods-action-icon>
                <van-goods-action-icon icon="home-o" text="返回首页" @click="goPage('index')"></van-goods-action-icon>
                <van-goods-action-button type="primary" text="立即购买" @click="confirm" style="margin-left: 25px;" />
            </van-goods-action>
        </div>

        <div class="utils-wrap">
            <van-dialog v-model="showConfirm" :showConfirmButton="false" :showCancelButton="false" style="width: 70%;border-radius: 12px;">
                <div class="confirm-dialog">
                    <img :src="require('@/assets/images/order/confirm_bg.png')" style="width: 100%;height: 90px;" />
                    <div class="fx-fill fx-center">
                        <div style="font-size: 15px;">请确保充值账号无误</div>
                        <div style="font-size: 15px;">提交后无法退换</div>
                        <div class="fc-tips" style="font-size: 12px;margin-top: 20px;">充值账号</div>
                        <div class="fc-status" style="font-size: 20px;margin: 10px 0;">{{chargeAccount}}</div>
                    </div>
                    <div class="confirm-img">
                        <img :src="require('@/assets/images/order/confirm_bg1.png')" style="top: 65px;right: 0px;width: 25px;" />
                        <img :src="require('@/assets/images/order/confirm_bg2.png')" style="top: 95px;left: 12px;width: 18px;" />
                        <img :src="require('@/assets/images/order/confirm_bg3.png')" style="top: 125px;right: 20px;width: 18px;" />
                        <img :src="require('@/assets/images/order/confirm_bg4.png')" style="top: 55px;left: 24%;width: 25px;" />
                    </div>
                    <div class="confirm-action">
                        <van-button size="mini" style="width: 42%;background-color: #eee;border-radius: 4px;" @click="cancel">取消</van-button>
                        <van-button type="primary" size="mini" style="width: 42%;border-radius: 4px;" @click="submit">确定</van-button>
                    </div>
                </div>
            </van-dialog>
        </div>
    </div>
</template>

<script>
import { validField } from '@/libs/common-utils/common-utils';

import { getProductSku, createRechargeOrder, getOrderDetail } from '@api/recharge-request'
export default {
    data() {
        return {
            pid: '',
            chargeAccount: '',
            chargeAccountAppend: '',
            skuIndex: 0,
            skuSecondIndex: 0,
            showConfirm: false,
            product: {},
            tpList: [],
        }
    },
    computed: {
        skuList() {
            let skuList = this.tpList[this.skuIndex]?.skuList;
            return skuList || [];
        },
        tpId() {
            return this.tpList[this.skuIndex].tpId;
        },
        skuId() {
            return this.skuList[this.skuSecondIndex].skuId;
        },
        serviceTel() {
            return this.$store.getters.baseData.serviceTel;
        }
    },
    created() {
        this.pid = this.$route.query.pid
        this.initPage();
    },
    methods: {
        confirm() {
            if (!this.chargeAccount) {
                this.$toast(this.product.placeholder)
                return;
            }

            if (this.product.placeholderAppend && !this.chargeAccountAppend) {
                this.$toast(this.product.placeholderAppend);
                return;
            }

            this.showConfirm = true;
        },
        submit() {
            //提交数据
            let params = {
                chargeAccount: this.chargeAccount,
                skuId: this.skuId
            }

            this.chargeAccountAppend ? params.chargeAccountAppend = this.chargeAccountAppend : '';
            createRechargeOrder(params).then((res) => {
                let orderNo = res;
                this.$goPage('store-order-pay', {
                    on: res
                })
                // getOrderDetail(res).then((res) => {
                //     console.log(res)
                // })

            })
        },
        cancel() {
            this.showConfirm = false;
        },
        call() {
            window.location.href = `tel://${this.serviceTel}`;
        },
        goPage(name) {
            this.$goPage(name)
        },
        getProductSku() {
            // if (!this.pid) return this.$goPage(-1);
            getProductSku(this.pid).then((res) => {
                this.product = res.subCategory;
                this.tpList = res.tpList;
            })
        },
        initPage() {
            this.getProductSku();
        }
    },
    watch: {
        skuIndex() {
            this.skuSecondIndex = 0;
        }
    },
    filters: {
        formatMoney(val) {
            return Number(val).toFixed(2)
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-wrap {
	height: 60px;
}

.confirm-dialog {
	display: flex;
	flex-direction: column;
	position: relative;

	.confirm-img {
		img {
			position: absolute;
		}
	}

	.confirm-action {
		width: 100%;
		padding: 8px 20px 20px;
		display: flex;
		justify-content: space-between;
	}
}


.body-wrap {
	flex: 1;
	position: relative;
	padding: 0 10px;
	.block {
		margin-bottom: 10px;
		.title {
			font-size: 14px;
			color: #15161a;
			font-weight: 700;
			padding: 10px 15px;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				width: 1.5vw;
				height: 40%;
				background: #fe1a29;
			}
		}

		.input {
			input {
				width: 100%;
				background-color: #ddd;
				font-size: 18px;
				border: 0;
				border-radius: 4px;
				padding: 0px 10px;
				line-height: 1.9em;
				height: 1.9em;
				&::placeholder {
					font-size: 12px;
					line-height: 1.9em;
					height: 1.9em;
				}
			}
		}

		.sku.sku-second-wrap {
			margin-top: 20px !important;
			overflow-y: visible;
			overflow-x: scroll;
			padding-top: 20px;
			@include scroll-bar-none;
		}

		.sku {
			display: flex;
			flex-direction: row;
			.sku-item {
				position: relative;
				z-index: 0;
				border: 1px solid $border-color;
				padding: 10px;
				border-radius: 4px;
				background-color: #fff;
				overflow: hidden;
				font-size: 14px;
				white-space: nowrap;
				margin-top: 10px;
				&::after {
					content: "";
					position: absolute;
					z-index: -1;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					opacity: 0;
					background-color: #ff3f3e;
					-webkit-box-shadow: 0 0.03rem 0.15rem rgba(255, 63, 62, 0.3);
					box-shadow: 0 0.03rem 0.15rem rgba(255, 63, 62, 0.3);
					-webkit-transition: 0.3s;
					transition: 0.3s;
					-webkit-transform: scale(0);
					transform: scale(0);
				}
				&.active::after {
					opacity: 1 !important;
					-webkit-transform: scale(1) !important;
					transform: scale(1) !important;
				}
				&.active {
					color: #fff !important;
				}
				&:not(:last-child) {
					margin-right: 10px;
				}
			}

			.sku-second {
				position: relative;
				z-index: 0;
				border: 1px solid $border-color;
				padding: 20px 12px 8px;
				border-radius: 4px;
				background-color: #fff;
				text-align: center;
				&::after {
					content: "";
					position: absolute;
					z-index: -1;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					opacity: 0;
					background-color: #ff6347;
					-webkit-box-shadow: 0 0.03rem 0.15rem rgba(255, 63, 62, 0.3);
					box-shadow: 0 0.03rem 0.15rem rgba(255, 63, 62, 0.3);
					-webkit-transition: 0.2s;
					transition: 0.2s;
					-webkit-transform: scale(0);
					transform: scale(0);
				}

				&.active::after {
					opacity: 0.1 !important;
					-webkit-transform: scale(1) !important;
					transform: scale(1) !important;
				}

				&:not(:first-child) {
					margin-left: 10px;
				}

				.sku-second--current {
					color: #fff;
					background-color: #15161a;
					padding: 2px 3px;
					border-top-left-radius: 4px;
					border-bottom-right-radius: 4px;
					position: absolute;
					top: -10px;
					left: 0;
					z-index: 999;
				}
				.sku-second--name {
					font: 3.2vw/4vw simhei;
					font-size: 18px;
				}
				.sku-second--origin {
					text-decoration: line-through;
					font-size: 12px;
					color: $tips-color;
					white-space: nowrap;
				}

				.sku-second--price {
					font-size: 18px;
					color: #ff4500;
					padding: 6px 0;
					// border-bottom: 1px solid $border-color;
				}

				.sku-second--common {
					padding: 6px 0;
					font-size: 14px;
					color: $tips-color;
				}

				.sku-second--common-price {
					text-decoration: line-through;
					color: $tips-color;
				}

				&.active .sku-second--common {
					color: #15161a;
				}
				&.active .sku-second--common-price {
					color: #a52a2a;
				}
			}
		}
	}
}
</style>
